import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const Head = (headProps: HeadProps) => {
  const { site } = useStaticQuery<QueryTypes>(Query)
  const { title } = headProps

  return (
    <Helmet>
      <title>{`${title} | ${site.siteMetadata.title}`}</title>
      <meta
        name="facebook-domain-verification"
        content="02poigd5n0w7nibn2epfu2thqcb9sy"
      />
    </Helmet>
  )
}

export default Head

type QueryTypes = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

type HeadProps = {
  title: string
}

const Query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
