import React, { Component } from "react"
import { Link } from "gatsby"

import * as headerStyles from "../styles/components/header.module.scss"

import { GiHamburgerMenu } from "react-icons/gi"
import { CgClose } from "react-icons/cg"

type MyProps = {}
type MyState = { isActive: boolean }

class Header extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props)
    this.state = {
      isActive: true,
    }
  }

  render() {
    const { isActive } = this.state

    return (
      <header className={headerStyles.header}>
        <h2>
          <Link to="/">
            Blacks Apps<span>.</span>
          </Link>
        </h2>

        {/* <DarkLightModeToggle /> */}

        <nav>
          <button
            onClick={() => {
              this.setState({ isActive: !isActive })
            }}
          >
            <GiHamburgerMenu size={17.5} className={headerStyles.icon} />
          </button>

          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/app-templates">App Templates</Link>
            </li>
            <li>
              <Link to="/articles">Articles</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact Me</Link>
            </li>
          </ul>

          <div className={`${isActive ? headerStyles.invisible : ""}`}>
            <div className={headerStyles.overlayContainer}>
              <button
                onClick={() => {
                  this.setState({ isActive: !isActive })
                }}
              >
                <CgClose size={17.5} className={headerStyles.icon} />
              </button>

              <ul>
                <li>
                  <Link to="/">
                    <div>Home</div>
                  </Link>
                </li>
                <li>
                  <Link to="/articles">
                    <div>Articles</div>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <div>About</div>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <div>Contact Me</div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
