import React from "react"

import Footer from "./footer"
import Header from "./header"

import "@fontsource/poppins/300.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/800-italic.css"

import "../styles/index.scss"
import * as layoutStyles from "../styles/components/layout.module.scss"

const Layout = (props: any) => {
  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
